import { render, staticRenderFns } from "./ContentDrawer.vue?vue&type=template&id=2c0c95ce&scoped=true&"
import script from "./ContentDrawer.vue?vue&type=script&lang=js&"
export * from "./ContentDrawer.vue?vue&type=script&lang=js&"
import style0 from "./ContentDrawer.vue?vue&type=style&index=0&id=2c0c95ce&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2c0c95ce",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBottomSheet } from 'vuetify/lib/components/VBottomSheet';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
installComponents(component, {VBottomSheet,VCard,VCardText,VDivider,VNavigationDrawer})

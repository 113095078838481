import { render, staticRenderFns } from "./AudioPlayer.vue?vue&type=template&id=127ab27a&scoped=true&"
import script from "./AudioPlayer.vue?vue&type=script&lang=js&"
export * from "./AudioPlayer.vue?vue&type=script&lang=js&"
import style0 from "./AudioPlayer.vue?vue&type=style&index=0&id=127ab27a&scss=true&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "127ab27a",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSlider } from 'vuetify/lib/components/VSlider';
installComponents(component, {VBtn,VCard,VIcon,VRow,VSlider})
